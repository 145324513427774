import { defaultListen } from '~/constants/defaultValues';

// hooks.useGetCurrentLanguage() === 'vn' ? '' : ''

const useCheckLoud = () => {
  const current = localStorage.getItem('isListen') || defaultListen;
  return JSON.parse(current);
};

export default useCheckLoud;
