import { combineReducers } from 'redux';

import auth from './auth/reducer';
import employee from './employee/reducer';
import menu from './menu/reducer';
import permissions from './permissions/reducer';
import roles from './roles/reducer';
import user from './user/reducer';
import work from './work/reducer';
import workTracking from './workTracking/reducer';
import calendar from './calendar/reducer';
import scheduleCalendar from './scheduleCalendar/reducer';
import scheduleBookCalendar from './scheduleBookCalendar/reducer';
import notification from './notifications/reducer';
import config from './config/reducer';
import common from './common/reducer';

const rootReducer = combineReducers({
  auth,
  employee,
  menu,
  roles,
  user,
  permissions,
  common,
  work,
  workTracking,
  calendar,
  scheduleCalendar,
  scheduleBookCalendar,
  notification,
  config,
});

export default rootReducer;
