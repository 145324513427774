import { all } from 'redux-saga/effects';

import auth from './auth/saga';
import employee from './employee/saga';
import permissions from './permissions/saga';
import roles from './roles/saga';
import user from './user/saga';
import common from './common/saga';
import work from './work/saga';
import workTracking from './workTracking/saga';
import calendar from './calendar/saga';
import scheduleCalendar from './scheduleCalendar/saga';
import scheduleBookCalendar from './scheduleBookCalendar/saga';
import notification from './notifications/saga';
import config from './config/saga';

export default function* rootSaga() {
  yield all([
    auth(),
    employee(),
    user(),
    roles(),
    permissions(),
    common(),
    work(),
    workTracking(),
    calendar(),
    scheduleCalendar(),
    scheduleBookCalendar(),
    notification(),
    config(),
  ]);
}
