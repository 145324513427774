import useCheckStatusOrder from './useCheckStatusOrder';
import useCheckValue from './useCheckValue';
import useConvertToSlug from './useConvertToSlug';
import useDebounce from './useDebounce';
import useGetAllPermissionOfUser from './useGetAllPermissionOfUser';
import useGetAllRoleOfUser from './useGetAllRoleOfUser';
import useGetCurrentLanguage from './useGetCurrentLanguage';
import useIsObjectEmpty from './useIsObjectEmpty';
import useOnClickOutside from './useOnClickOutside';
import usePagination from './usePagination';
import useParseCurrency from './useParseCurrency';
import useGetSessionOfDay from './useGetSessionOfDay';
import useAddDays from './useAddDays';
import useClearSpecialCharacters from './useClearSpecialCharacters';
import useTranslate from './useTranslate';
import useCapitalize from './useCapitalize';
import useGetNextStatusOrder from './useGetNextStatusOrder';
import useGetSizeDevice from './useGetSizeDevice';
import useClipboard from './useClipboard';
import useCheckLoud from './useCheckLoud';
import useGetField from './useGetField';

const hooks = {
  usePagination,
  useConvertToSlug,
  useIsObjectEmpty,
  useGetCurrentLanguage,
  useDebounce,
  useGetAllPermissionOfUser,
  useCheckValue,
  useOnClickOutside,
  useParseCurrency,
  useCheckStatusOrder,
  useGetAllRoleOfUser,
  useGetSessionOfDay,
  useAddDays,
  useClearSpecialCharacters,
  useTranslate,
  useCapitalize,
  useGetNextStatusOrder,
  useGetSizeDevice,
  useClipboard,
  useCheckLoud,
  useGetField,
};

export default hooks;
