// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const signInRequest = createAction('SIGN_IN_REQUEST');
export const signInSuccess = createAction('SIGN_IN_SUCCESS');
export const signInFailure = createAction('SIGN_IN_FAILURE');

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');
export const resetChangePasswordState = createAction(
  'RESET_CHANGE_PASSWORD_STATE',
);

export const requireChangePasswordRequest = createAction(
  'REQUIRE_CHANGE_PASSWORD_REQUEST',
);
export const requireChangePasswordSuccess = createAction(
  'REQUIRE_CHANGE_PASSWORD_SUCCESS',
);
export const requireChangePasswordFailure = createAction(
  'REQUIRE_CHANGE_PASSWORD_FAILURE',
);
export const resetRequireChangePasswordState = createAction(
  'RESET_REQUIRE_CHANGE_PASSWORD_STATE',
);

export const resetAuthState = createAction('RESET_AUTH_STATE');
