// import { statusOrderLang } from '~/constants/statusOrder';

const useGetNextStatusOrder = (status) => {
  let newStatus;
  switch (status) {
    case 'new':
      newStatus = 'printed';
      break;
    case 'printed':
      newStatus = 'waiting';
      break;
    case 'waiting':
      newStatus = 'delivery';
      break;
    case 'delivery':
      newStatus = 'delivered';
      break;
    case 'delivered':
      newStatus = 'paid';
      break;
    case 'canceled':
      newStatus = 'refunded';
      break;
  }
  return newStatus;
};

export default useGetNextStatusOrder;
