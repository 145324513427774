/* eslint-disable import/no-extraneous-dependencies */
import { put, call, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';

import * as Action from './actions';

function* getListCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro(payload?.params ? `/schedules?${payload.params}` : '/schedules'));
    yield put(Action.getListScheduleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.getListScheduleFailure(message));
    }
  }
}

function* getListEmployee({ payload }) {
  try {
    const response = yield call(() => axiosMicro(`/users?search=email:${payload?.params}`));
    yield put(Action.getListEmployeeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.getListEmployeeFailure(message));
    }
  }
}

function* getListWorkPlace() {
  try {
    const response = yield call(() => axiosMicro('/work-places'));
    yield put(Action.getListWorkPlaceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.getListWorkPlaceFailure(message));
    }
  }
}
// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Action.getListScheduleRequest, getListCalendar);
  yield takeLatest(Action.getListEmployeeRequest, getListEmployee);
  yield takeLatest(Action.getListWorkPlaceRequest, getListWorkPlace);
}
