// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllProductsCommonRequest: false,
  isGetAllProductsCommonSuccess: false,
  isGetAllProductsCommonFailure: false,
  allProductsCommonState: {},
  //
  isGetAllProductTypesCommonRequest: false,
  isGetAllProductTypesCommonSuccess: false,
  isGetAllProductTypesCommonFailure: false,
  allProductTypesCommonState: {},
  //
  isGetAllRegionGroupsCommonRequest: false,
  isGetAllRegionGroupsCommonSuccess: false,
  isGetAllRegionGroupsCommonFailure: false,
  allRegionGroupsCommonState: {},
  //
  isGetPriceProductByIDRequest: false,
  isGetPriceProductByIDSuccess: false,
  isGetPriceProductByIDFailure: false,
  priceProductByIDState: {},
  //
  isGetOrderPriceRequest: false,
  isGetOrderPriceSuccess: false,
  isGetOrderPriceFailure: false,
  orderPriceState: {},
  //
  isGetOrderPriceInEditRequest: false,
  isGetOrderPriceInEditSuccess: false,
  isGetOrderPriceInEditFailure: false,
  orderPriceInEditState: {},
  //
  isGetAllShippingCommonRequest: false,
  isGetAllShippingCommonSuccess: false,
  isGetAllShippingCommonFailure: false,
  getAllShippingCommonState: {},
  //
  isGetPriceShippingCommonRequest: false,
  isGetPriceShippingCommonSuccess: false,
  isGetPriceShippingCommonFailure: false,
  priceShippingCommonState: {},
  //
  isGetPriceShippingInEditCommonRequest: false,
  isGetPriceShippingInEditCommonSuccess: false,
  isGetPriceShippingInEditCommonFailure: false,
  priceShippingInEditCommonState: {},
  //
  isGetAllEmployeesCommonRequest: false,
  isGetAllEmployeesCommonSuccess: false,
  isGetAllEmployeesCommonFailure: false,
  allEmployeesCommonState: {},
  //
  isGetAllCustomerCommonRequest: false,
  isGetAllCustomerCommonSuccess: false,
  isGetAllCustomerCommonFailure: false,
  allCustomerCommonState: {},
  //
  isGetAllWorkPlacesCommonRequest: false,
  isGetAllWorkPlacesCommonSuccess: false,
  isGetAllWorkPlacesCommonFailure: false,
  allWorkPlacesCommonState: {},
  //
  selectedItems: {},
  //
  isGetAllExportWarehouseCommonRequest: false,
  isGetAllExportWarehouseCommonSuccess: false,
  isGetAllExportWarehouseCommonFailure: false,
  listExportWarehouseCommonState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all products
    [Actions.getAllProductsCommonRequest]: (state) => ({
      ...state,
      isGetAllProductsCommonRequest: true,
      isGetAllProductsCommonSuccess: false,
      isGetAllProductsCommonFailure: false,
    }),
    [Actions.getAllProductsCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductsCommonRequest: false,
      isGetAllProductsCommonSuccess: true,
      isGetAllProductsCommonFailure: false,
      allProductsCommonState: payload,
    }),
    [Actions.getAllProductsCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductsCommonRequest: false,
      isGetAllProductsCommonSuccess: false,
      isGetAllProductsCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all product types
    [Actions.getAllProductTypesCommonRequest]: (state) => ({
      ...state,
      isGetAllProductTypesCommonRequest: true,
      isGetAllProductTypesCommonSuccess: false,
      isGetAllProductTypesCommonFailure: false,
    }),
    [Actions.getAllProductTypesCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductTypesCommonRequest: false,
      isGetAllProductTypesCommonSuccess: true,
      isGetAllProductTypesCommonFailure: false,
      allProductTypesCommonState: payload,
    }),
    [Actions.getAllProductTypesCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductTypesCommonRequest: false,
      isGetAllProductTypesCommonSuccess: false,
      isGetAllProductTypesCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all region groups
    [Actions.getAllRegionGroupsCommonRequest]: (state) => ({
      ...state,
      isGetAllRegionGroupsCommonRequest: true,
      isGetAllRegionGroupsCommonSuccess: false,
      isGetAllRegionGroupsCommonFailure: false,
    }),
    [Actions.getAllRegionGroupsCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupsCommonRequest: false,
      isGetAllRegionGroupsCommonSuccess: true,
      isGetAllRegionGroupsCommonFailure: false,
      allRegionGroupsCommonState: payload,
    }),
    [Actions.getAllRegionGroupsCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupsCommonRequest: false,
      isGetAllRegionGroupsCommonSuccess: false,
      isGetAllRegionGroupsCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get price product by ID
    [Actions.getPriceProductByIDRequest]: (state) => ({
      ...state,
      isGetPriceProductByIDRequest: true,
      isGetPriceProductByIDSuccess: false,
      isGetPriceProductByIDFailure: false,
    }),
    [Actions.getPriceProductByIDSuccess]: (state, { payload }) => ({
      ...state,
      isGetPriceProductByIDRequest: false,
      isGetPriceProductByIDSuccess: true,
      isGetPriceProductByIDFailure: false,
      priceProductByIDState: payload,
    }),
    [Actions.getPriceProductByIDFailure]: (state, { payload }) => ({
      ...state,
      isGetPriceProductByIDRequest: false,
      isGetPriceProductByIDSuccess: false,
      isGetPriceProductByIDFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetPriceProductByIDState]: (state) => ({
      ...state,
      isGetPriceProductByIDRequest: false,
      isGetPriceProductByIDSuccess: false,
      isGetPriceProductByIDFailure: false,
      priceProductByIDState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get order price
    [Actions.getOrderPriceRequest]: (state) => ({
      ...state,
      isGetOrderPriceRequest: true,
      isGetOrderPriceSuccess: false,
      isGetOrderPriceFailure: false,
    }),
    [Actions.getOrderPriceSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderPriceRequest: false,
      isGetOrderPriceSuccess: true,
      isGetOrderPriceFailure: false,
      orderPriceState: payload,
    }),
    [Actions.getOrderPriceFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderPriceRequest: false,
      isGetOrderPriceSuccess: false,
      isGetOrderPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderPriceState]: (state) => ({
      ...state,
      isGetOrderPriceRequest: false,
      isGetOrderPriceSuccess: false,
      isGetOrderPriceFailure: false,
      orderPriceState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get order price in edit
    [Actions.getOrderPriceInEditRequest]: (state) => ({
      ...state,
      isGetOrderPriceInEditRequest: true,
      isGetOrderPriceInEditSuccess: false,
      isGetOrderPriceInEditFailure: false,
    }),
    [Actions.getOrderPriceInEditSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderPriceInEditRequest: false,
      isGetOrderPriceInEditSuccess: true,
      isGetOrderPriceInEditFailure: false,
      orderPriceInEditState: payload,
    }),
    [Actions.getOrderPriceInEditFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderPriceInEditRequest: false,
      isGetOrderPriceInEditSuccess: false,
      isGetOrderPriceInEditFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderPriceInEditState]: (state) => ({
      ...state,
      isGetOrderPriceInEditRequest: false,
      isGetOrderPriceInEditSuccess: false,
      isGetOrderPriceInEditFailure: false,
      orderPriceInEditState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get order price
    [Actions.getAllShippingCommonRequest]: (state) => ({
      ...state,
      isGetAllShippingCommonRequest: true,
      isGetAllShippingCommonSuccess: false,
      isGetAllShippingCommonFailure: false,
    }),
    [Actions.getAllShippingCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllShippingCommonRequest: false,
      isGetAllShippingCommonSuccess: true,
      isGetAllShippingCommonFailure: false,
      getAllShippingCommonState: payload,
    }),
    [Actions.getAllShippingCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShippingCommonRequest: false,
      isGetAllShippingCommonSuccess: false,
      isGetAllShippingCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get price shipping
    [Actions.getPriceShippingCommonRequest]: (state) => ({
      ...state,
      isGetPriceShippingCommonRequest: true,
      isGetPriceShippingCommonSuccess: false,
      isGetPriceShippingCommonFailure: false,
    }),
    [Actions.getPriceShippingCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingCommonRequest: false,
      isGetPriceShippingCommonSuccess: true,
      isGetPriceShippingCommonFailure: false,
      priceShippingCommonState: payload,
    }),
    [Actions.getPriceShippingCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingCommonRequest: false,
      isGetPriceShippingCommonSuccess: false,
      isGetPriceShippingCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get price shipping in edit
    [Actions.getPriceShippingInEditCommonRequest]: (state) => ({
      ...state,
      isGetPriceShippingInEditCommonRequest: true,
      isGetPriceShippingInEditCommonSuccess: false,
      isGetPriceShippingInEditCommonFailure: false,
    }),
    [Actions.getPriceShippingInEditCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingInEditCommonRequest: false,
      isGetPriceShippingInEditCommonSuccess: true,
      isGetPriceShippingInEditCommonFailure: false,
      priceShippingInEditCommonState: payload,
    }),
    [Actions.getPriceShippingInEditCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingInEditCommonRequest: false,
      isGetPriceShippingInEditCommonSuccess: false,
      isGetPriceShippingInEditCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all employees
    [Actions.getAllEmployeesCommonRequest]: (state) => ({
      ...state,
      isGetAllEmployeesCommonRequest: true,
      isGetAllEmployeesCommonSuccess: false,
      isGetAllEmployeesCommonFailure: false,
    }),
    [Actions.getAllEmployeesCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllEmployeesCommonRequest: false,
      isGetAllEmployeesCommonSuccess: true,
      isGetAllEmployeesCommonFailure: false,
      allEmployeesCommonState: payload,
    }),
    [Actions.getAllEmployeesCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllEmployeesCommonRequest: false,
      isGetAllEmployeesCommonSuccess: false,
      isGetAllEmployeesCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all customer
    [Actions.getAllCustomerCommonRequest]: (state) => ({
      ...state,
      isGetAllCustomerCommonRequest: true,
      isGetAllCustomerCommonSuccess: false,
      isGetAllCustomerCommonFailure: false,
    }),
    [Actions.getAllCustomerCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerCommonRequest: false,
      isGetAllCustomerCommonSuccess: true,
      isGetAllCustomerCommonFailure: false,
      allCustomerCommonState: payload,
    }),
    [Actions.getAllCustomerCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerCommonRequest: false,
      isGetAllCustomerCommonSuccess: false,
      isGetAllCustomerCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all work places
    [Actions.getAllWorkPlacesCommonRequest]: (state) => ({
      ...state,
      isGetAllWorkPlacesCommonRequest: true,
      isGetAllWorkPlacesCommonSuccess: false,
      isGetAllWorkPlacesCommonFailure: false,
    }),
    [Actions.getAllWorkPlacesCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWorkPlacesCommonRequest: false,
      isGetAllWorkPlacesCommonSuccess: true,
      isGetAllWorkPlacesCommonFailure: false,
      allWorkPlacesCommonState: payload,
    }),
    [Actions.getAllWorkPlacesCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWorkPlacesCommonRequest: false,
      isGetAllWorkPlacesCommonSuccess: false,
      isGetAllWorkPlacesCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    [Actions.selectedItemsChange]: (state, { payload }) => ({
      ...state,
      selectedItems: payload,
    }),

    // #region
    [Actions.getAllExportWarehouseCommonRequest]: (state) => ({
      ...state,
      isGetAllExportWarehouseCommonRequest: true,
      isGetAllExportWarehouseCommonSuccess: false,
      isGetAllExportWarehouseCommonFailure: false,
    }),
    [Actions.getAllExportWarehouseCommonSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllExportWarehouseCommonRequest: false,
      isGetAllExportWarehouseCommonSuccess: true,
      isGetAllExportWarehouseCommonFailure: false,
      listExportWarehouseCommonState: payload,
    }),
    [Actions.getAllExportWarehouseCommonFailure]: (state, { payload }) => ({
      ...state,
      isGetAllExportWarehouseCommonRequest: false,
      isGetAllExportWarehouseCommonSuccess: false,
      isGetAllExportWarehouseCommonFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetCommonState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
