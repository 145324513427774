import useGetCurrentLanguage from '~/hooks/useGetCurrentLanguage';

export const statusOrder = [
  'new',
  'printed',
  'waiting',
  'delivery',
  'delivered',
  'paid',
  'canceled',
  'refunded',
  'late',
];

export const statusOrderLang = [
  {
    value: 'new',
    label: useGetCurrentLanguage() === 'vn' ? 'Đơn hàng mới' : 'New',
    color: 'secondary',
  },
  {
    value: 'transfer',
    label: useGetCurrentLanguage() === 'vn' ? 'Chờ chuyển khoản' : 'Waiting transfer',
    color: 'warning',
  },
  {
    value: 'transferred',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã chuyển khoản' : 'Transferred',
    color: 'success',
  },
  {
    value: 'printed',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã in' : 'Printed',
    color: 'primary',
  },
  {
    value: 'waiting',
    label: useGetCurrentLanguage() === 'vn' ? 'Chờ vận chuyển' : 'Waiting',
    color: 'warning',
  },
  {
    value: 'delivery',
    label: useGetCurrentLanguage() === 'vn' ? 'Đang giao hàng' : 'Delivery',
    color: 'info',
  },
  {
    value: 'delivered',
    label:
      useGetCurrentLanguage() === 'vn' ? 'Giao hàng thành công' : 'Delivered',
    color: 'success',
  },
  {
    value: 'paid',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã nhận tiền' : 'Paid',
    color: 'light',
  },
  {
    value: 'canceled',
    label: useGetCurrentLanguage() === 'vn' ? 'Hủy đơn' : 'Canceled',
    color: 'danger',
  },
  {
    value: 'refunded',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã hoàn hàng' : 'Refunded',
    color: 'dark',
  },
  {
    value: 'late',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã quá hạn' : 'Exceeded',
    color: 'warning',
  },
  {
    value: 'updated',
    label: useGetCurrentLanguage() === 'vn' ? 'Đã cập nhật' : 'Updated',
    color: 'danger',
  },
  {
    value: 'processing',
    label: useGetCurrentLanguage() === 'vn' ? 'Đang xử lý' : 'Processing',
    color: 'warning',
  },
];

export const statusOrderEdit = ['new', 'printed'];
export const statusOrderPrinted = ['new', 'printed', 'waiting'];
export const statusOrderUpdate = [
  'printed',
  'waiting',
  'delivery',
  'delivered',
  // 'paid',
  // 'canceled',
  // 'refunded',
];

/*
new: đơn mới tạo
  | -> printed: đã in
    | -> waiting: chờ shipper lấy hàng
      | -> delivery: đang giao hàng
        | -> delivered: giao thành công
          | -> paid: đã nhận tiền
  | -> canceled: Hủy đơn
    | -> refunded: Đã hoàn hàng
*/
