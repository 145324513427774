// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductsCommonRequest = createAction(
  'GET_ALL_PRODUCTS_COMMON_REQUEST',
);
export const getAllProductsCommonSuccess = createAction(
  'GET_ALL_PRODUCTS_COMMON_SUCCESS',
);
export const getAllProductsCommonFailure = createAction(
  'GET_ALL_PRODUCTS_COMMON_FAILURE',
);

export const getAllProductTypesCommonRequest = createAction(
  'GET_ALL_PRODUCT_TYPES_COMMON_REQUEST',
);
export const getAllProductTypesCommonSuccess = createAction(
  'GET_ALL_PRODUCT_TYPES_COMMON_SUCCESS',
);
export const getAllProductTypesCommonFailure = createAction(
  'GET_ALL_PRODUCT_TYPES_COMMON_FAILURE',
);

export const getAllRegionGroupsCommonRequest = createAction(
  'GET_ALL_REGION_GROUPS_COMMON_REQUEST',
);
export const getAllRegionGroupsCommonSuccess = createAction(
  'GET_ALL_REGION_GROUPS_COMMON_SUCCESS',
);
export const getAllRegionGroupsCommonFailure = createAction(
  'GET_ALL_REGION_GROUPS_COMMON_FAILURE',
);

export const getPriceProductByIDRequest = createAction(
  'GET_PRICE_PRODUCT_BY_ID_REQUEST',
);
export const getPriceProductByIDSuccess = createAction(
  'GET_PRICE_PRODUCT_BY_ID_SUCCESS',
);
export const getPriceProductByIDFailure = createAction(
  'GET_PRICE_PRODUCT_BY_ID_FAILURE',
);
export const resetGetPriceProductByIDState = createAction(
  'RESET_GET_PRICE_PRODUCT_BY_ID_STATE',
);

export const getOrderPriceRequest = createAction('GET_ORDER_PRICE_REQUEST');
export const getOrderPriceSuccess = createAction('GET_ORDER_PRICE_SUCCESS');
export const getOrderPriceFailure = createAction('GET_ORDER_PRICE_FAILURE');
export const resetGetOrderPriceState = createAction(
  'RESET_GET_ORDER_PRICE_STATE',
);

export const getOrderPriceInEditRequest = createAction('GET_ORDER_PRICE_IN_EDIT_REQUEST');
export const getOrderPriceInEditSuccess = createAction('GET_ORDER_PRICE_IN_EDIT_SUCCESS');
export const getOrderPriceInEditFailure = createAction('GET_ORDER_PRICE_IN_EDIT_FAILURE');
export const resetGetOrderPriceInEditState = createAction(
  'RESET_GET_ORDER_PRICE_IN_EDIT_STATE',
);

export const getAllShippingCommonRequest = createAction(
  'GET_ALL_SHIPPING_COMMON_REQUEST',
);
export const getAllShippingCommonSuccess = createAction(
  'GET_ALL_SHIPPING_COMMON_SUCCESS',
);
export const getAllShippingCommonFailure = createAction(
  'GET_ALL_SHIPPING_COMMON_FAILURE',
);

export const getPriceShippingCommonRequest = createAction(
  'GET_PRICE_SHIPPING_COMMON_REQUEST',
);
export const getPriceShippingCommonSuccess = createAction(
  'GET_PRICE_SHIPPING_COMMON_SUCCESS',
);
export const getPriceShippingCommonFailure = createAction(
  'GET_PRICE_SHIPPING_COMMON_FAILURE',
);

export const getPriceShippingInEditCommonRequest = createAction(
  'GET_PRICE_SHIPPING_IN_EDIT_COMMON_REQUEST',
);
export const getPriceShippingInEditCommonSuccess = createAction(
  'GET_PRICE_SHIPPING_IN_EDIT_COMMON_SUCCESS',
);
export const getPriceShippingInEditCommonFailure = createAction(
  'GET_PRICE_SHIPPING_IN_EDIT_COMMON_FAILURE',
);

export const getAllEmployeesCommonRequest = createAction(
  'GET_ALL_EMPLOYEES_COMMON_REQUEST',
);
export const getAllEmployeesCommonSuccess = createAction(
  'GET_ALL_EMPLOYEES_COMMON_SUCCESS',
);
export const getAllEmployeesCommonFailure = createAction(
  'GET_ALL_EMPLOYEES_COMMON_FAILURE',
);

export const getAllCustomerCommonRequest = createAction(
  'GET_ALL_CUSTOMER_COMMON_REQUEST',
);
export const getAllCustomerCommonSuccess = createAction(
  'GET_ALL_CUSTOMER_COMMON_SUCCESS',
);
export const getAllCustomerCommonFailure = createAction(
  'GET_ALL_CUSTOMER_COMMON_FAILURE',
);

export const getAllWorkPlacesCommonRequest = createAction(
  'GET_ALL_WORK_PLACES_COMMON_REQUEST',
);
export const getAllWorkPlacesCommonSuccess = createAction(
  'GET_ALL_WORK_PLACES_COMMON_SUCCESS',
);
export const getAllWorkPlacesCommonFailure = createAction(
  'GET_ALL_WORK_PLACES_COMMON_FAILURE',
);

export const selectedItemsChange = createAction('SELECTED_ITEMS_CHANGE');

export const getAllExportWarehouseCommonRequest = createAction('GET_ALL_EXPORT_WAREHOUSE_COMMON_REQUEST');
export const getAllExportWarehouseCommonSuccess = createAction('GET_ALL_EXPORT_WAREHOUSE_COMMON_SUCCESS');
export const getAllExportWarehouseCommonFailure = createAction('GET_ALL_EXPORT_WAREHOUSE_COMMON_FAILURE');

export const resetCommonState = createAction('RESET_COMMON_STATE');
