/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getAllScheduleBooksCalendarRequest = createAction('GET_ALL_SCHEDULE_BOOKS_CALENDAR_REQUEST');
export const getAllScheduleBooksCalendarSuccess = createAction('GET_ALL_SCHEDULE_BOOKS_CALENDAR_SUCCESS');
export const getAllScheduleBooksCalendarFailure = createAction('GET_ALL_SCHEDULE_BOOKS_CALENDAR_FAILURE');

export const createScheduleBookCalendarRequest = createAction('CREATE_SCHEDULE_BOOK_CALENDAR_REQUEST');
export const createScheduleBookCalendarSuccess = createAction('CREATE_SCHEDULE_BOOK_CALENDAR_SUCCESS');
export const createScheduleBookCalendarFailure = createAction('CREATE_SCHEDULE_BOOK_CALENDAR_FAILURE');
export const resetCreateScheduleBookCalendarState = createAction('RESET_CREATE_SCHEDULE_BOOK_CALENDAR_STATE');

export const updateScheduleBookCalendarRequest = createAction('UPDATE_SCHEDULE_BOOK_CALENDAR_REQUEST');
export const updateScheduleBookCalendarSuccess = createAction('UPDATE_SCHEDULE_BOOK_CALENDAR_SUCCESS');
export const updateScheduleBookCalendarFailure = createAction('UPDATE_SCHEDULE_BOOK_CALENDAR_FAILURE');
export const resetUpdateScheduleBookCalendarState = createAction('RESET_UPDATE_SCHEDULE_BOOK_CALENDAR_STATE');

export const deleteScheduleBookCalendarRequest = createAction('DELETE_SCHEDULE_BOOK_CALENDAR_REQUEST');
export const deleteScheduleBookCalendarSuccess = createAction('DELETE_SCHEDULE_BOOK_CALENDAR_SUCCESS');
export const deleteScheduleBookCalendarFailure = createAction('DELETE_SCHEDULE_BOOK_CALENDAR_FAILURE');
export const resetDeleteScheduleBookCalendarState = createAction('RESET_DELETE_SCHEDULE_BOOK_CALENDAR_STATE');

export const resetScheduleBookCalendarState = createAction('RESET_SCHEDULE_BOOK_CALENDAR_STATE');
