/* eslint-disable import/no-extraneous-dependencies */
import { put, call, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Action from './actions';

function* getAllScheduleBooksCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/schedule-books?${payload.params}` : '/schedule-books',
    ));
    yield put(Action.getAllScheduleBooksCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.getAllScheduleBooksCalendarFailure(message));
    }
  }
}

function* createScheduleBookCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/schedule-books', payload));
    yield put(Action.createScheduleBookCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.createScheduleBookCalendarFailure(message));
    }
  }
}

function* updateScheduleBookCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/schedule-books/${payload.id}`, payload.params));
    yield put(Action.updateScheduleBookCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.updateScheduleBookCalendarFailure(message));
    }
  }
}

function* deleteScheduleBookCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/schedule-books/${payload}`));
    yield put(Action.deleteScheduleBookCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.deleteScheduleBookCalendarFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    Action.getAllScheduleBooksCalendarRequest,
    getAllScheduleBooksCalendar,
  );
  yield takeLatest(
    Action.createScheduleBookCalendarRequest,
    createScheduleBookCalendar,
  );
  yield takeLatest(
    Action.updateScheduleBookCalendarRequest,
    updateScheduleBookCalendar,
  );
  yield takeLatest(
    Action.deleteScheduleBookCalendarRequest,
    deleteScheduleBookCalendar,
  );
}
