const workplace = {
  //
  'dashboard.home': 'Trang chủ',
  'dashboard.workplace.name': 'Tên nơi làm việc',
  'dashboard.workplace.place': 'Địa điểm',
  'dashboard.workplace.ipAddress': 'Địa chỉ IP',
  'dashboard.workplace.note': 'Ghi chú',
  'dashboard.workplace.manager': 'Quản lý địa điểm làm việc',
  'dashboard.workplace.list': 'Danh sách địa điểm làm việc',
  'dashboard.workplace.add': 'Thêm nơi làm việc',
  'dashboard.workplace.edit': 'Chỉnh sửa nơi làm việc',
  'dashboard.workplace.delete': 'Xóa nơi làm việc',
  'dashboard.workplace.confirm-add': 'Xác nhận thêm nơi làm việc',
  'dashboard.workplace.confirm-sure-add': 'Bạn chắc chắn sẽ thêm nơi làm việc này?',
  'dashboard.workplace.confirm-edit': 'Xác nhận nơi chỉnh sửa nơi làm việc',
  'dashboard.workplace.confirm-sure-edit': 'Bạn chắc chắn sẽ chỉnh sửa nơi làm việc này?',
  'dashboard.workplace.confirm-delete': 'Xác nhận xóa nơi làm việc',
  'workTracking.notWorking': 'Không làm',
  'dashboard.workplace.confirm-sure-delete': 'Bạn chắc chắn sẽ xóa nơi làm việc này?',
  'dashboard.schedule.edit': 'Cập nhật lịch làm việc',
  // form
  'dashboard.workplace.longitude': 'Kinh độ',
  'dashboard.workplace.latitude': 'Vĩ độ',
  'dashboard.workplace.name-require': 'Vui lòng nhập tên nơi làm việc',
  'dashboard.workplace.longitude-require': 'Vui lòng nhập kinh độ',
  'dashboard.workplace.latitude-require': 'Vui lòng nhập vĩ độ',
  'dashboard.workTracking.day': 'Ngày',
  'dashboard.workTracking.hours': 'Giờ',
  'dashboards.revenue': 'Doanh thu',

  // notification
  'dashboard.workplace.addSuccess': 'Thêm nơi làm việc thành công',
  'dashboard.workplace.addFailure': 'Thêm thất bại nơi làm việc',
  'dashboard.workplace.editSuccess': 'Chỉnh sửa nơi làm việc thành công',
  'dashboard.workplace.editFailure': 'Chỉnh sửa thất bại nơi làm việc',
  'dashboard.workplace.deleteSuccess': 'Xóa nơi làm việc thành công',
  'dashboard.workplace.deletedFailure': 'Xóa thất bại nơi làm việc',
  'dashboard.this-day': 'Hôm nay',
  'dashboard.this-week': 'Tuần',
  'dashboard.this-month': 'Tháng',
  'dashboard.this-year': 'Năm',
  'dashboard.revenue-number': 'Biểu đồ doanh số',
  'dashboard.revenue-export': 'Kho: Xuất bán - hoàn đến',
  'dashboard.revenue-grow': 'Biểu đồ tăng trưởng',
  'dashboard.warehouse.will-outInStock': 'Sắp hết hàng',
  'dashboard.warehouse.inventory': 'Tồn kho',
  'dashboard.revenue-grow.revenue': 'Doanh thu',
  'dashboard.revenue-grow.number': 'Số lượng',
  'dashboard.order-export': 'Số lượng đơn hàng',
};

const schedule = {
  //
  'dashboard.schedule.code': 'ID',
  'dashboard.schedule.date': 'Ngày',
  'dashboard.schedule.time': 'Thời gian',
  'dashboard.schedule.note': 'Ghi chú',
  'dashboard.schedule.reason': 'Lý do',
  'dashboard.schedule.reason.require': 'Vui lòng nhập lý do',
  'dashboard.schedule.status': 'Trạng thái',
  'dashboard.schedule.businessList': 'Danh sách lịch làm việc',
  'dashboard.schedule.manager': 'Quản lý lịch làm việc',
  'dashboard.schedule.add': 'Thêm lịch làm việc',
  'dashboard.schedule.confirm-add': 'Xác nhận thêm lịch làm việc',
  'dashboard.schedule.confirm-sure-add': 'Bạn có chắc chắn để thêm lịch làm việc này?',
  'dashboard.schedule.confirm-edit': 'Xác nhận chỉnh sửa lịch làm việc',
  'dashboard.schedule.confirm-sure-edit': 'Bạn có chắc chắn để chỉnh sửa lịch làm việc này?',
  'dashboard.schedule.confirm-delete': 'Xác nhận xóa lịch làm việc',
  'dashboard.schedule.confirm-sure-delete': 'Bạn có chắc chắn xóa lịch làm việc này?',
  'dashboard.schedule.accept': 'Chấp nhận lịch làm việc',
  'dashboard.schedule.acceptFailure': 'Chấp nhận lịch làm việc thất bại',

  // form
  'dashboard.schedule.employee': 'Nhân viên',
  'dashboard.schedule.employee-require': 'Vui lòng chọn một nhân viên',
  'dashboard.schedule.place': 'Địa điểm',
  'dashboard.schedule.place-require': 'Vui lòng chọn một nơi để làm việc',
  'dashboard.schedule.date-start': 'Ngày bắt đầu',
  'dashboard.schedule.date-start-require': 'Vui lòng chọn một ngày bắt đầu',
  'dashboard.schedule.date-end': 'Ngày kết thúc',
  'dashboard.schedule.date-end-require': 'Vui lòng chọn một ngày kết thúc',
  'dashboard.schedule.time-from': 'Thời gian bắt đầu',
  'dashboard.schedule.time-from-require': 'Vui lòng chọn thời gian bắt đầu',
  'dashboard.schedule.time-to': 'Thời gian kết thúc',
  'dashboard.schedule.time-to-require': 'Vui lòng chọn thời gian kết thúc',
  // notification

  'dashboard.schedule.addSuccess': 'Thêm lịch làm việc thành công',
  'dashboard.schedule.addFailure': 'Thêm Lịch làm việc thất bại',
  'dashboard.schedule.editSuccess': 'Chỉnh sửa lịch làm việc thành công',
  'dashboard.schedule.editFailure': 'Chỉnh sửa lịch làm việc thất bại',
  'dashboard.schedule.deleteSuccess': 'Xóa lịch làm việc thành công',
  'dashboard.schedule.deletedFailure': 'Xóa thất bại lịch làm việc',

  // errors
  'dashboard.schedule.timeStart': 'Ngày bắt đầu không thể trước',
  'dashboard.schedule.timeEnd': 'Ngày kết thúc không thể trước ngày bắt đầu',
  'dashboard.schedule.timeEndLarger': 'Thời gian kết thúc không thể trước khi bắt đầu',
};

const vacation = {
  //
  'dashboard.vacation.list': 'Danh sách lịch nghỉ phép',
  'dashboard.vacation.list.employee': 'Thống kê nhân viên',
  'dashboard.vacation.manager': 'Quản lý lịch nghỉ phép',
  'dashboard.vacation.add': 'Thêm lịch nghỉ phép',
  'dashboard.vacation.edit': 'Chỉnh sửa lịch nghỉ phép',
  'dashboard.vacation.confirm-add': 'Xác nhận thêm lịch nghỉ phép',
  'dashboard.vacation.confirm-sure-add': 'Bạn có chắc chắn thêm lịch nghỉ phép này không?',
  'dashboard.vacation.confirm-edit': 'Xác nhận chỉnh sửa lịch nghỉ phép',
  'dashboard.vacation.confirm-sure-edit': 'Bạn có chắc chắn để chỉnh sửa lịch trình kỳ nghỉ phép này?',
  'dashboard.vacation.accept': 'Chấp nhận lịch nghỉ phép',
  'dashboard.vacation.acceptFailure': 'Chấp nhận lịch nghỉ phép thất bại',
  'dashboard.vacation.paidLeave': 'Nghỉ có lương',
  'dashboard.vacation.unPaidLeave': 'Nghỉ phép không lương',
  'dashboard.vacation.book': 'Xin nghỉ phép',
  'dashboard.vacation.user-backup': 'Người thay thế',
  'dashboard.vacation.name': 'Tên',
  // notification
  'dashboard.vacation.addSuccess': 'Thêm lịch nghỉ thành công',
  'dashboard.vacation.addFailure': 'Thêm Lịch trình kỳ nghỉ thất bại',
  'dashboard.vacation.editSuccess': 'Chỉnh sửa lịch nghỉ thành công',
  'dashboard.vacation.editFailure': 'Chỉnh sửa Lịch nghỉ Lỗi thất bại',
  'dashboard.vacation.deleteSuccess': 'Xóa lịch nghỉ thành công',
  'dashboard.vacation.deletedFailure': 'Xóa thất bại trong lịch trình nghỉ',
  'dashboard.vacation.pending': 'Chờ',
  'dashboard.vacation.noPaid': 'Nghỉ phép không lương',
  'dashboard.vacation.paid': 'Nghỉ phép có lương',
};

const workTracking = {
  //
  'dashboard.workTracking.name': 'Tên vận chuyển',
  'dashboard.workTracking.employee': 'Nhân viên',
  'dashboard.workTracking.place': 'Địa điểm',
  'dashboard.workTracking.time': 'Thời gian',
  'dashboard.workTracking.list': 'Log thời gian làm việc',
  'dashboard.workTracking.manager': 'Quản lý thời gian làm việc',
};

const notification = {
  'dashboard.notification.list': 'Danh sách thông báo',
  'dashboard.notification.unRead': 'Thông báo chưa đọc',
  'dashboard.notification.order': 'Thông báo về đơn hàng',
  'dashboard.notification.notice': 'Thông báo',
  'dashboard.notification.time': 'Thời gian',
  'dashboard.notification.content': 'Nội dung thông báo',
  'dashboard.notification.system': 'Thông báo hệ thống',
};

const loginPage = {
  'auth.login.hetmet': 'Trang đăng nhập',
  'auth.login.title': 'Trang đăng nhập',
  'auth.login.sub-title-1': 'Đăng nhập bằng thông tin đăng nhập',
  'auth.login.sub-title-2': 'của bạn để truy cập vào hệ thống!',
  'auth.login.btn-login': 'Đăng nhập',
  'auth.login.err-msg': 'Email hoặc mật khẩu của bạn đã sai. Vui lòng kiểm tra lại.',
  'common.label.email': 'Email',
  'common.label.password': 'Mật khẩu',
  'common.label.captcha': 'Mã bảo vệ',
};

const vnLangDashboard = {
  ...workplace,
  ...schedule,
  ...vacation,
  ...workTracking,
  ...notification,
  ...loginPage,
};

export default vnLangDashboard;
