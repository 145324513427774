/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllNotifications({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/notifications/my?include=order,user${payload.params}` : '/notifications/my?include=order,user'));
    if (response?.status === 200) {
      yield put(Actions.getAllNotificationPageSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllNotificationPageFailure(messages));
    }
  }
}

function* changeStatusNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/notifications/my/read', { id: payload.toString() }));
    if (response?.status === 204) {
      yield put(Actions.changeStatusNotificationPageSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.changeStatusNotificationPageFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllNotificationPageRequest, getAllNotifications);
  yield takeLatest(Actions.changeStatusNotificationPageRequest, changeStatusNotification);
}
