/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getAllNotificationPageRequest = createAction('GET_ALL_NOTIFICATION_PAGE_REQUEST');
export const getAllNotificationPageSuccess = createAction('GET_ALL_NOTIFICATION_PAGE_SUCCESS');
export const getAllNotificationPageFailure = createAction('GET_ALL_NOTIFICATION_PAGE_FAILURE');

export const changeStatusNotificationPageRequest = createAction('CHANGE_STATUS_NOTIFICATION_PAGE_REQUEST');
export const changeStatusNotificationPageSuccess = createAction('CHANGE_STATUS_NOTIFICATION_PAGE_SUCCESS');
export const changeStatusNotificationPageFailure = createAction('CHANGE_STATUS_NOTIFICATION_PAGE_FAILURE');

export const resetNotificationState = createAction('RESET_NOTIFICATION_STATE');
