import vnLangDashboard from './Personal/Dashboard/vn';
import vnLangEmployee from './Personal/Employee/vn';
import vnLangPermission from './Personal/Permission/vn';
import vnLangRole from './Personal/Role/vn';

const errorMsg = {
  //
  'error.email-empty': 'Please enter email address',
  'error.email-invalid': 'Invalid email address',
  //
  'error.password-empty': 'Please enter password',
  'error.password-length': 'Value must be longer than 4 characters',
  'error.password-format':
    'Value a least one character upper case letter, lower case letter, number and special character',
  'error.password-number': 'Value a least one character number',
  'error.password-special': 'Value a least one special character ',
  'error.password-capital': 'Value a least one character upper case letter',
  'error.password-lower': 'Value a least one character lower case letter',
  //
  'error.id-employee-empty': 'Please enter employee id',
  //
  'error.full-name-empty': 'Please enter full name',
  //
  'error.birthday-empty': 'Please enter birthday',
  //
  'error.id-personal-empty': 'Please enter ID',
  'error.id-personal-invalid': 'ID must be have 10 characters',
  'error.id-personal-date': 'Please enter ID date',
  'error.id-personal-place': 'Please enter ID issuer',
  //
  'error.phone-number-empty': 'Please enter phone number',
  'error.phone-number-invalid': 'Phone number must be have 10 characters',
  //
  'error.address-empty': 'Please enter address',
  //
  'error.start-date-work-empty': 'Please enter date start work',
  //
  'error.cv-status-empty': 'Please enter application status',
  //
  'error.account-status-empty': 'Please enter account status',
};

const authPage = {
  'auth.login-title': 'Login page',
  'auth.label-email': 'E-mail',
  'auth.label-password': 'Password',
  'auth.forgot-password-link': 'Forgot password?',
  'auth.login-button': 'Sign in',
  'auth.login-error-title': 'Login Error',
  'auth.login-error-msg': 'Email or password is incorrect',
};

const topNav = {
  'sidebar.dropdown.account': 'Account',
  'sidebar.dropdown.change-password': 'Change password',
  'sidebar.dropdown.support': 'Support',
  'sidebar.dropdown.sign-out': 'Sign out',
  'topnav.dropdown.change-team.title': 'Danh sách team',
  'topnav.dropdown.item.personal': 'Tài khoản cá nhân',
  'topnav.noti.change-current-team.request': 'Đang chuyển không gian làm việc',
  'topnav.noti.change-current-team.failure':
    'Chuyển không gian làm việc thất bại',
};

const sidebar = {
  //
  'sidebar.dashboard': 'Dashboard',
  'sidebar.welcome': 'Welcome',
  //
  'sidebar.employee': 'Employee Manager',
  'sidebar.list-employees': 'List employees',
  'sidebar.add-employee': 'Add employee',
  //
  'sidebar.docs': 'Docs',
  'sidebar.dropdown.bookVacation': 'Xin nghỉ phép',
  'sidebar.dropdown.bookSchedule': 'Lịch công tác',
  'sidebar.warehouse': 'Danh sách kho',
  'sidebar.warehouse.manager': 'Quản lý kho',
  'sidebar.warehouse.meko': 'Tồn kho',
  'sidebar.warehouse.check': 'Kiểm tra tồn kho',

};

const user = {
  'user.currentPassword': 'Current password',
  'user.newPassword': 'New password',
  'user.confirmNewPassword': 'Confirm password',
  'user.changePasswordButton': 'Change password',
  'user.changePasswordSuccess': 'Change password success',
  'user.currentPassword.notValid': 'Current password is incorrect',
  'user.information': 'Thông tin cá nhân',
  'user.schedule': 'Lịch',
  'user.work-tracking': 'Hoạt động',
  'user.schedule.date-from': 'Ngày bắt đầu',
  'user.schedule.time-from': 'Giờ bắt đầu',
  'user.schedule.date-to': 'Ngày kết thúc',
  'user.schedule.time-to': 'Giờ kết thúc',
  'user.schedule-info': 'Lịch công tác',
  'user.vacation': 'Lịch nghỉ phép',
  'user.work-tracking.late': 'Trễ',
  'user.work-tracking.ok': 'Đúng giờ',
  'user.work-tracking.early': 'Sớm',
  'user.work-tracking.notMatch': 'Không đúng địa điểm',
  'user.timekeeping': 'Thống kê giờ công',
  'user.workTracking': 'Log thời gian làm việc',
  'user.profile.account-disable': 'Không hoạt động',
  'user.profile.account-enable': 'Đang hoạt động',

};

const table = {
  'table.title.name': 'Full name',
  'table.title.email': 'Email',
  'table.title.gender': 'Gender',
  'table.title.birthday': 'Birthday',
};

const common = {
  'common.content-hi': 'Chào buổi',
  'common.notice': 'Thông báo',
  'common.createdAt': 'Ngày tạo ',
  'common.updatedAt': 'Ngày cập nhật',
  'common.date': 'Ngày',
  'common.status': 'Trạng thái',
  'common.loading': 'Đang tải..',
  'common.disable': 'Vô hiệu hóa',
  'common.enable': 'Cho phép',
  'common.select': 'Lựa chọn',
  'common.bigSizeImage': 'Quá lớn, xin vui lòng chọn một bức ảnh khác',
  'common.image': 'Hình ảnh',
  'common.note': 'Ghi chú',
  'common.description': 'Mô tả',
  'common.hours': 'Giờ',
  'common.employee.name': 'Tên nhân viên',
  'common.type': 'Loại',
  'common.mon': 'T2',
  'common.tue': 'T3',
  'common.wed': 'T4',
  'common.thur': 'T5',
  'common.fri': 'T6',
  'common.sat': 'T7',
  'common.sun': 'CN',
  'common.date.total': 'Tổng ngày',
  'common.name': 'Tên',
  'common.total': 'Tổng',
  'common.filter': 'Lọc',
  'common.filter.member': 'Thành viên',
  'common.reason': 'Lý do',
  'common.number': 'Số lượng',
  'common.money': 'Tổng tiền',
  'common.place-export': 'Nơi xuất',
  'common.place-export-receive': 'Nơi nhận',
  'common.code': 'Mã',
  'common.by': 'Theo',
  'common.from': 'từ',
  'common.to': 'cho',
  'common.shipping': 'Vận chuyển',
  'common.update.contemporary.success': 'Cập nhật đồng loạt thành công',
  'common.update.contemporary.failure': 'Cập nhật đồng loạt thất bại',
  'common.title': 'Tiêu đề',
  'common.month': 'Tháng',
  'common.year': 'Năm',
  'common.on': 'Bật',
  'common.off': 'Tắt',
  'common.search': 'Tìm kiếm',
  'common.city': 'Tỉnh',
  'common.district': 'Quận',
  'common.transport': 'Chuyển qua',
  'common.transport.confirm': 'Xác nhận chuyển sang',
  'common.transport.confirmSure': 'Bạn chắc chắn chuyển sang',
  'common.copy.success': 'Sao chép thành công',
  'common.schedule': 'Nghỉ phép',
  'common.schedule.statistic': 'Thống kê nghỉ',
  'common.object': 'Đối tượng',
  'common.time': 'Thời gian',
  'common.rewarded': 'Khen thưởng',
  'common.honored': 'Tôn vinh',
  'common.active': 'Kích hoạt',
  'common.information.empty': 'Thông tin bị thiếu',
  'common.accept': 'Chấp nhận',
  'common.refuse': 'Từ chối',
  'common.pending': 'Đang chờ',
  'common.content': 'Nội dung',
  'common.staff': 'Nhân viên',
  'common.update.place': 'Vui lòng cập nhật tỉnh thành(quận huyện) khách hàng',
  'common.discount.code': 'Mã giảm',
  'common.info.voucher': 'Thông tin voucher',
  'common.endow': 'Ưu đãi',
  'common.payment': 'Thanh toán',
  'common.score': 'Điểm',
  'common.day.online': 'Làm việc',
  'common.day.offline': 'Nghỉ',
  'common.turnOn': 'Bật',
  'common.turnOff': 'Tắt',
  'common.sale': 'Bán',
  'common.method': 'Hình thức',
  'common.fee': 'Phí',
  'common.ship': 'Ship',
  'common.price': 'Giá',
  'common.save.fee': 'Lưu phí',
  'common.box': 'Thùng',
  'common.cash': 'Chi phí',
  'common.totalCash': 'Tổng thu hộ',
  'common.canceled': 'Đã hủy',
  'common.history': 'Lịch sử',
  'common.none': 'Không',
};
const dateFormat = {
  'date.today': 'Hôm nay',
  'date.yesterday': 'Hôm qua',
  'date.this-week': 'Tuần này',
  'date.last-week': 'Tuần trước',
  'date.this-month': 'Tháng này',
  'date.last-month': 'Tháng trước',
  'date.from-today': 'ngày từ hôm nay',
};

const sort = {
  'sort.asc': 'Tăng dần',
  'sort.desc': 'Giảm dần',
  'sort.more': 'Hơn',
  'sort.sortBy': 'Thứ tự',
  'sort.orderBy': 'Sắp xếp theo',
  'sort.search': 'Tìm kiếm...',
  'sort.employee': 'Sắp xếp theo nhân viên',
  'sort.month': 'Sắp xếp theo tháng',
  'sort.orderByEmployee': 'Sắp xếp theo nhân viên',
  'sort.date': 'Theo ngày',
  'sort.status': 'Trạng thái',
  'sort.staff': 'Nhân viên',
};
const action = {
  'action.name': 'Hoạt động',
};

const value = {
  'value.empty': 'Trống',
  'value.empty-list': 'Danh sách trống',
  'value.view': 'Xem',
  'value.total': 'Tổng cộng',
  'value.error.less.zero': 'Số lượng không thể bé hơn 0',
  'value.error.number': 'Vui lòng nhập số lượng',
  'value.phone.invalid': 'Số điện thoại không hợp lệ',
};

const btn = {
  'btn.update': 'Cập nhật',
  'btn.close': 'Đóng',
  'btn.back': 'Trờ lại',
  'btn.yes': 'Đồng ý',
  'btn.cancel': 'Hủy',
  'btn.save': 'Lưu',
  'btn.finish': 'Hoàn thành',
  'btn.get-price': 'Nhận báo giá',
  'btn.transport-order': 'Đẩy đơn ',
  'btn.save-config': 'Lưu cấu hình',
};

const usLangLib = {
  ...btn,
  ...value,
  ...action,
  ...sort,
  ...errorMsg,
  ...authPage,
  ...topNav,
  ...sidebar,
  ...user,
  ...table,
  ...dateFormat,
  ...vnLangDashboard,
  ...vnLangEmployee,
  ...vnLangPermission,
  ...common,
  ...vnLangRole,
};

export default usLangLib;
