const role = {
  'role.name': 'Tên vai trò',
  'role.name.require': 'Vui lòng nhập tên vai',
  'role.name.min': 'Ít nhất 2 ký tự',
  'role.name.max': 'Tối đa 20 ký tự',
  'role.description.require': 'Vui lòng nhập một mô tả cho vai trò',
  'role.permissions.require': 'Vui lòng chọn ít nhất 1 quyền',
  'role.list': 'Danh sách vai trò',
  'role.permission': 'Danh sách quyền',
  'role.manager': 'Quản lý vai trò',
  'role.add': 'Thêm vai trò',
  'role.update': 'Cập nhật vai trò',
  'role.list.permission': 'Danh sách quyền',
  'role.confirm-add': 'Xác nhận thêm vai trò',
  'role.confirm-sure-add': 'Bạn chắc chắn sẽ thêm vai trò này?',
  'role.confirm-delete': 'Xác nhận vai trò xóa',
  'role.confirm-sure-delete': 'Bạn chắc chắn sẽ xóa vai trò này?',
  'role.confirm-update': 'Xác nhận vai trò cập nhật',
  'role.confirm-sure-update': 'Bạn chắc chắn sẽ cập nhật vai trò này?',
  // notification
  'role.addSuccess': 'Thêm vai trò thành công',
  'role.updateSuccess': 'Cập nhật vai trò thành công',
  'role.deleteSuccess': 'Xóa vai trò thành công',
  'role.deleteFailure': 'Vai trò xóa không thành công',
  'role.updateFailure': 'Vai trò cập nhật không thành công',
  'role.addFailure': 'Thêm vai trò không thành công',
  'role.addFailure.before': 'Vai trò này đã được tạo ra trước',
};

const vnLangRole = {
  ...role,
};

export default vnLangRole;
