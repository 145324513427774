import { defaultLocale } from '~/constants/defaultValues';

// hooks.useGetCurrentLanguage() === 'vn' ? '' : ''

const useGetCurrentLanguage = () => {
  const currentLanguage = localStorage.getItem('currentLanguage') || defaultLocale;
  return currentLanguage;
};

export default useGetCurrentLanguage;
