const permission = {
  'permission.name': 'Full name',
  'permission.role': 'Role',
  'permission.list': 'List of authorized employees',
  'permission.permission': 'Permission',
  'permission.add': 'Delegation to employees',
  'permission.role.add': 'Add roles for employee',
  'permission.role.update': 'Update roles for employee',
  'permission.confirm-add': 'Confirm attach roles for employee',
  'permission.confirm-sure-add': 'You definitely want to add a role to this employee?',
  'permission.confirm-update': 'Confirm update roles for employee',
  'permission.confirm-sure-update': 'You definitely want to update a role to this employee?',
  'permission.confirm-delete': 'Confirm delete roles for employee',
  'permission.confirm-sure-delete': 'You definitely want to delete a role to this employee?',
  // form
  'permission.email': "Email's employee",
  'permission.email.require': 'Please select the employee to be authorized',
  'permission.role.require': 'Please select at least 1 role',
  'permission.role.list': 'Role list',
  'permission.role.name': 'Role name',
  'permission.role.description': 'Description',

  // notifications
  'permission.syncSuccess': 'Authorization successfully',
  'permission.revokeRole': 'Delete employee role successfully',
  'permission.role.addFailure': 'Add roles for employees is failed',
  'permission.role.updateFailure': 'Update roles for employee is failed',
  'permission.role.deleteFailure': 'Delete roles for employee is failed',
};

const usLangPermission = {
  ...permission,
};

export default usLangPermission;
