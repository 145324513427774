import usLangDashboard from './Personal/Dashboard/us';
import usLangEmployee from './Personal/Employee/us';
import usLangPermission from './Personal/Permission/us';
import usLangRole from './Personal/Role/us';

const topNav = {
  'sidebar.dropdown.account': 'Account',
  'sidebar.dropdown.change-password': 'Change password',
  'sidebar.dropdown.support': 'Support',
  'sidebar.dropdown.sign-out': 'Sign out',
  'topnav.dropdown.change-team.title': 'Team list',
  'topnav.dropdown.item.personal': 'Personal account',
  'topnav.noti.change-current-team.request': 'Shifting work space',
  'topnav.noti.change-current-team.failure': 'Moving work space failed',
};

const sidebar = {
  //
  'sidebar.dashboard': 'Dashboard',
  'sidebar.welcome': 'Welcome',
  //
  'sidebar.employee': 'Employee Manager',
  'sidebar.list-employees': 'List employees',
  'sidebar.add-employee': 'Add employee',
  //
  'sidebar.docs': 'Docs',

  //

  'sidebar.dropdown.bookVacation': 'Book vacation',
  'sidebar.dropdown.bookSchedule': 'Book schedule',
  'sidebar.warehouse': 'Ware house',
  'sidebar.warehouse.manager': 'Manager warehouse',
  'sidebar.warehouse.meko': 'Inventory',
  'sidebar.warehouse.check': 'Check warehouse',
};

const user = {
  'user.currentPassword': 'Current password',
  'user.newPassword': 'New password',
  'user.confirmNewPassword': 'Confirm password',
  'user.changePasswordButton': 'Change password',
  'user.changePasswordSuccess': 'Change password success',
  'user.currentPassword.notValid': 'Current password is incorrect',
  'user.information': 'Information',
  'user.schedule': 'Schedule',
  'user.work-tracking': 'Action',
  'user.schedule.date-from': 'Start date',
  'user.schedule.time-from': 'Start time',
  'user.schedule.date-to': 'End date',
  'user.schedule.time-to': 'End time',
  'user.schedule-info': 'Schedule',
  'user.vacation': 'Calendar vacation',
  'user.work-tracking.late': 'Late',
  'user.work-tracking.ok': 'On time',
  'user.work-tracking.early': 'Early',
  'user.work-tracking.notMatch': 'Not match place',
  'user.timekeeping': 'Timekeeping',
  'user.workTracking': 'Log time work tracking',
  'user.profile.account-disable': 'Inactive',
  'user.profile.account-enable': 'Activate',
};

const common = {
  'common.content-hi': 'Good',
  'common.notice': 'Notice',
  'common.createdAt': 'Created at',
  'common.updatedAt': 'Updated at',
  'common.status': 'Status',
  'common.loading': 'Loading..',
  'common.disable': 'Disable',
  'common.enable': 'Enable',
  'common.select': 'Select',
  'common.bigSizeImage': 'Too big, please choose another photo',
  'common.image': 'Image',
  'common.note': 'Note',
  'common.description': 'Description',
  'common.date': 'Date',
  'common.hours': 'Hours',
  'common.employee.name': 'Employee name',
  'common.type': 'Type',
  'common.mon': 'Mon',
  'common.tue': 'Tue',
  'common.wed': 'Wed',
  'common.thur': 'Thur',
  'common.fri': 'Fri',
  'common.sat': 'Sat',
  'common.sun': 'Sun',
  'common.date.total': 'Total day',
  'common.name': 'Name',
  'common.total': 'Total',
  'common.filter': 'Filter',
  'common.filter.member': 'Member',
  'common.reason': 'Reason',
  'common.number': 'Number',
  'common.money': 'Total',
  'common.place-export': 'Place out',
  'common.place-export-receive': 'Place receive',
  'common.code': 'Code',
  'common.by': 'By',
  'common.from': 'from',
  'common.to': 'to',
  'common.shipping': 'Shipping',
  'common.update.contemporary.success': 'Update contemporary success',
  'common.update.contemporary.failure': 'Update contemporary failure',
  'common.title': 'Title',
  'common.month': 'Month',
  'common.year': 'Year',
  'common.on': 'On',
  'common.off': 'Off',
  'common.search': 'Search',
  'common.city': 'City',
  'common.district': 'District',
  'common.transport': 'Transport',
  'common.transport.confirm': 'Confirm',
  'common.transport.confirmSure': 'You will definitely',
  'common.copy.success': 'Copy success',
  'common.schedule': 'Schedule',
  'common.schedule.statistic': 'Schedule statistic',
  'common.object': 'Object',
  'common.time': 'Time',
  'common.rewarded': 'Rewarded',
  'common.honored': 'Honored',
  'common.active': 'Active',
  'common.update.place': 'Please update place customer',
  'common.discount.code': 'Code discount',
  'common.info.voucher': 'Voucher information',
  'common.endow': 'Endow',
  'common.information.empty': 'Information is missing',
  'common.accept': 'Accept',
  'common.refuse': 'Refuse',
  'common.pending': 'Pending',
  'common.payment': 'Payment',
  'common.content': 'Content',
  'common.staff': 'Staff',
  'common.score': 'Score',
  'common.day.online': 'Online',
  'common.day.offline': 'Offline',
  'common.turnOn': 'Turn on',
  'common.turnOff': 'Turn off',
  'common.sale': 'Sale',
  'common.method': 'Method',
  'common.fee': 'Fee',
  'common.ship': 'Ship',
  'common.price': 'Price',
  'common.save.fee': 'Save fee',
  'common.box': 'Box',
  'common.cash': 'Cash',
  'common.totalCash': 'Total cash',
  'common.canceled': 'Canceled',
  'common.history': 'History',
  'common.none': 'None',
};
const sort = {
  'sort.asc': 'Ascending',
  'sort.desc': 'Decrease',
  'sort.more': 'More',
  'sort.sortBy': 'Sort By',
  'sort.orderBy': 'Order By',
  'sort.search': 'Search...',
  'sort.employee': 'Order By Employee',
  'sort.month': 'Order by month',
  'sort.orderByEmployee': 'Order by employee',
  'sort.date': 'Date',
  'sort.status': 'Status',
  'sort.staff': 'Staff',
};
const action = {
  'action.name': 'Action',
};

const dateFormat = {
  'date.today': 'Today',
  'date.yesterday': 'Yesterday',
  'date.this-week': 'This week',
  'date.last-week': 'Last week',
  'date.this-month': 'This month',
  'date.last-month': 'Last month',
  'date.from-today': 'days up to today',
};

const value = {
  'value.empty': 'Empty',
  'value.empty-list': 'List is empty',
  'value.view': 'View',
  'value.total': 'Total',
  'value.error.less.zero': 'Number can not less than 0',
  'value.error.number': 'Please enter number',
  'value.phone.invalid': 'Phone number is not valid',
};

const btn = {
  'btn.update': 'Update',
  'btn.close': 'Close',
  'btn.back': 'Back',
  'btn.yes': 'Yes',
  'btn.cancel': 'Cancel',
  'btn.save': 'Save',
  'btn.finish': 'Finish',
  'btn.get-price': 'Get price',
  'btn.transport-order': 'Transport Order',
  'btn.save-config': 'Save Config',
};

const usLangLib = {
  ...btn,
  ...value,
  ...action,
  ...sort,
  ...topNav,
  ...sidebar,
  ...user,
  ...common,
  ...dateFormat,
  ...usLangDashboard,
  ...usLangEmployee,
  ...usLangPermission,
  ...usLangRole,
};

export default usLangLib;
